<footer>
  <dgx-ui-wl-footer-navigation
    [usefulLinks]="usefulLinks"
    [legalLinks]="legalLinks"
  ></dgx-ui-wl-footer-navigation>

  <dgx-ui-wl-footer-legal>
    <p>
      EDF is a trading name used by EDF Energy Customers Ltd. Reg. No. 02228297.
      Registered office is 90 Whitfield Street, London, W1T 4EZ, incorporated in
      England and Wales. EDF is an Appointed Representative of Domestic &
      General Insurance PLC, which is authorised by the Prudential Regulation
      Authority and regulated by the Financial Conduct Authority and the
      Prudential Regulation Authority. BoilerCare Value and BoilerCare Total
      policies are provided by Domestic & General Insurance PLC Registered
      Office: Swan Court, 11 Worple Road, Wimbledon, London SW19 4JS, United
      Kingdom. Registered in England and Wales, Company No. 485850. Domestic &
      General Insurance PLC is authorised by the Prudential Regulation Authority
      and regulated by the Financial Conduct Authority and the Prudential
      Regulation Authority. Domestic & General Insurance PLC is an insurance
      undertaking, not an intermediary. They are the underwriter of the
      insurance and do not provide a personal recommendation. Full details on
      how to submit a complaint can be found in the terms and Conditions.
      Domestic & General Insurance PLC pay their sales agents periodic
      incentives (such as bonus payments) if they meet certain quality and sales
      targets. Maintenance and Support Plans (BoilerCare Gold, BoilerCare
      Platinum) are provided by Domestic & General Services Limited. Registered
      in England and Wales. Company No. 1970780. Registered office: Swan Court,
      11 Worple Road, Wimbledon, London SW19 4JS.
    </p>
  </dgx-ui-wl-footer-legal>
  <dgx-ui-wl-footer-social
    [copyrightOwner]="copyrightOwner"
    [socialLinks]="socialLinks"
  ></dgx-ui-wl-footer-social>
</footer>
