import { HttpErrorResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CheckoutBasketActions } from '@common/data-access-checkout-basket';
import {
  FeatureConfigFacade,
  FeatureConfigPartialState,
} from '@common/data-access-feature-config';
import {
  applianceIsHeating,
  BuildConfigService,
  distinctUntilChangedDeep,
  ENVIRONMENT,
  ErrorService,
  filterNullUndefined,
  LoaderService,
  mapToLatestFrom,
} from '@common/util-foundation';
import {
  Basket,
  CheckoutBasket,
  CoverType,
  DiscountStoreConfig,
  Environment,
  SelectedBasketItemApplianceQuote,
} from '@common/util-models';
import {
  Actions,
  concatLatestFrom,
  createEffect,
  ofType,
  OnInitEffects,
} from '@ngrx/effects';
import { Action, select, Store } from '@ngrx/store';
import { AuthFacade } from '@shared/data-access-auth';
import { NavigationActions } from '@shared/data-access-navigation';
import { Observable, of } from 'rxjs';
import {
  catchError,
  concatMap,
  debounceTime,
  delayWhen,
  filter,
  first,
  map,
  mergeMap,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { QuotesApiService } from '../services/quotes-api.service';
import * as QuotesPageSelectors from './quotes-page.selectors';
import {
  getDefaultCoverType,
  getSelectedItemApplianceQuote,
} from './quotes-page.selectors';
import * as QuotesActions from './quotes.actions';
import { QuotesPartialState } from './quotes.reducer';
import {
  getBasketId,
  getQuotesRemoteStateUnstable,
  getRouteParamsAutoSelect,
  getSelectedItems,
  getUnusedItemIds,
} from './quotes.selectors';

@Injectable()
export class QuotesEffects implements OnInitEffects {
  indicateQuoteLoadingState$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(QuotesActions.createQuote),
        filter(() => !this.environment.multiBasket),
        tap(() => this.loaderService.showLoader()),
        switchMap(() =>
          this.actions$.pipe(
            ofType(
              QuotesActions.createQuoteSuccess,
              QuotesActions.createQuoteFailure
            )
          )
        ),
        tap(() => this.loaderService.hideLoader())
      ),
    { dispatch: false }
  );

  sendQuotePageTagsOnCreateQuoteSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(QuotesActions.createQuoteSuccess),
      map(() => QuotesActions.sendQuotesPageTags())
    )
  );

  autoSelectCover$ = createEffect(() =>
    this.actions$.pipe(
      ofType(QuotesActions.createQuoteSuccess),
      filter(
        (action) => !this.environment.multiBasket || !!action.autoSubmitFlow
      ),
      mapToLatestFrom(
        this.store.select(getDefaultCoverType, { isMultiQuoteFlow: false })
      ),
      filterNullUndefined(),
      map((coverType: CoverType) =>
        QuotesActions.autoSelectCoverType({
          coverType,
        })
      )
    )
  );

  multiQuoteAutoSelectCover$ = createEffect(() =>
    this.actions$.pipe(
      ofType(QuotesActions.replaceSelectedItemsWithSingleItem),
      filter(() => !!this.environment.multiBasket),
      mapToLatestFrom(
        this.store.select(getDefaultCoverType, { isMultiQuoteFlow: true })
      ),
      filterNullUndefined(),
      map((coverType: CoverType) =>
        QuotesActions.autoSelectCoverType({
          coverType,
        })
      )
    )
  );

  autoSelectExcessValue$ = createEffect(() =>
    this.actions$.pipe(
      ofType(QuotesActions.autoSelectCoverType),
      withLatestFrom(this.store.select(getRouteParamsAutoSelect)),
      filter(([, paramsFromRoute]) => !!paramsFromRoute),
      map(([, paramsFromRoute]) =>
        QuotesActions.selectExcessValue({
          excessValue: paramsFromRoute?.excess ?? 0,
        })
      )
    )
  );

  routeToQuotePageOnAutoSelectCover$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(QuotesActions.autoSelectCoverType),
        tap(() => {
          this.router.navigateByUrl(this.config.quotePage);
        })
      ),
    { dispatch: false }
  );

  proceedToQuotePage$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(QuotesActions.proceedToQuote),
        tap(() => this.router.navigateByUrl(this.config.quotePage))
      ),
    { dispatch: false }
  );

  stepCompleted$ = createEffect(() =>
    this.actions$.pipe(
      ofType(QuotesActions.createQuoteSuccess),
      map(() =>
        NavigationActions.stepCompleted({
          step: this.config.applianceDetailsPage,
        })
      )
    )
  );

  proceedToCheckout$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CheckoutBasketActions.proceedToCheckout),
      switchMap(() => this.authFacade.isLoggedIn$),
      map((isLoggedIn) =>
        isLoggedIn
          ? CheckoutBasketActions.checkoutAlreadyLoggedIn()
          : CheckoutBasketActions.checkoutNotLoggedInYet()
      )
    )
  );

  checkoutNotLoggedInYet$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CheckoutBasketActions.checkoutNotLoggedInYet),
        map(() => {
          this.router.navigateByUrl(this.config.checkoutLandingPage);
        })
      ),
    {
      dispatch: false,
    }
  );

  removeUnusedItemsRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        QuotesActions.createQuoteSuccess,
        QuotesActions.removeCurrentItem,
        QuotesActions.removeSelectedItem,
        QuotesActions.replaceSelectedItemsWithSingleItem
      ),
      delayWhen(() => this.quoteRemoteStateUnstable$),
      debounceTime(50),
      withLatestFrom(this.store.pipe(select(getUnusedItemIds))),
      filter(([, unusedItemIds]: [Action, string[]]) => !!unusedItemIds.length),
      map(() => QuotesActions.removeUnusedItems())
    )
  );

  removeUnusedItems$ = createEffect(() =>
    this.actions$.pipe(
      ofType(QuotesActions.removeUnusedItems),
      withLatestFrom(
        this.store.pipe(select(getBasketId)),
        this.store.pipe(select(getUnusedItemIds))
      ),
      concatMap(
        ([, basketId, unusedItemIds]: [Action, string | undefined, string[]]) =>
          this.quotesService
            .removeItemsFromQuote(basketId, ...unusedItemIds)
            .pipe(
              map((basket: Basket) =>
                QuotesActions.removeUnusedItemsSuccess({
                  basket,
                })
              ),
              catchError((error: HttpErrorResponse) =>
                of(QuotesActions.removeUnusedItemsFailure({ error }))
              )
            )
      )
    )
  );

  routeToApplianceDetailsOnEntireSelectedItemsRemoval$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(QuotesActions.removeSelectedItem),
        withLatestFrom(this.store.pipe(select(getSelectedItems))),
        filter(
          ([, selectedItems]) =>
            !!this.environment?.multiBasket && !selectedItems?.length
        ),
        tap(() =>
          this.router.navigateByUrl(
            this.buildConfigService.config.applianceDetailsPage
          )
        )
      ),
    { dispatch: false }
  );

  setMostRecentActionMessageOnAddToBasket$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        QuotesActions.addToSelectedItems,
        QuotesActions.replaceSelectedItemsWithSingleItem
      ),
      mergeMap(({ selectedItem }) =>
        this.store.select(getSelectedItemApplianceQuote(selectedItem)).pipe(
          first(),
          filterNullUndefined(),
          map((quote) => ({
            selectedItem,
            quote,
          }))
        )
      ),
      filterNullUndefined(),
      concatLatestFrom(({ selectedItem }) => [
        this.featureConfig.discountStore$,
        this.store.pipe(
          select(
            QuotesPageSelectors.isSelectedItemPurchaseDateWithinDiscountMonths(
              selectedItem
            )
          )
        ),
      ]),
      map(
        ([
          { quote },
          discountStore,
          isSelectedItemPurchaseDateWithinThreeMonths,
        ]: [
          {
            quote: SelectedBasketItemApplianceQuote;
          },
          DiscountStoreConfig | undefined,
          boolean | undefined
        ]) =>
          applianceIsHeating(quote?.applianceDetails)
            ? QuotesActions.clearMostRecentActionMessage()
            : QuotesActions.setMostRecentActionMessage({
                mostRecentActionMessage: `${quote.applianceDetails.brandName} ${
                  quote.applianceDetails.applianceName
                } protection has been added to your quote${
                  discountStore?.discount &&
                  isSelectedItemPurchaseDateWithinThreeMonths === false
                    ? ' with discount'
                    : ''
                }`,
              })
      )
    )
  );

  setMostRecentActionMessageOnRemoveFromBasket$ = createEffect(() =>
    this.actions$.pipe(
      ofType(QuotesActions.removeSelectedItem),
      mergeMap(({ selectedItem }) =>
        this.store
          .select(getSelectedItemApplianceQuote(selectedItem))
          .pipe(first())
      ),
      filterNullUndefined(),
      map((quote: SelectedBasketItemApplianceQuote) =>
        applianceIsHeating(quote?.applianceDetails)
          ? QuotesActions.clearMostRecentActionMessage()
          : QuotesActions.setMostRecentActionMessage({
              mostRecentActionMessage: `${quote.applianceDetails.brandName} ${quote.applianceDetails.applianceName} protection has been removed from your quote`,
            })
      )
    )
  );

  setCheckoutBasket$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        QuotesActions.addToSelectedItems,
        QuotesActions.autoSelectCoverType,
        QuotesActions.createQuoteSuccess,
        QuotesActions.removeSelectedItem,
        QuotesActions.replaceSelectedItemsWithSingleItem,
        QuotesActions.selectCoverType,
        QuotesActions.selectExcessValue,
        QuotesActions.setInitialSelectCoverType
      ),
      mapToLatestFrom(this.store.select(QuotesPageSelectors.getCheckoutBasket)),
      filterNullUndefined(),
      distinctUntilChangedDeep(),
      map((basket: CheckoutBasket) =>
        CheckoutBasketActions.setBasket({ basket })
      )
    )
  );

  addToSelectedItemsRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(QuotesActions.addToSelectedItemsRequest),
      withLatestFrom(this.store.select(QuotesPageSelectors.getMultiItemQuotes)),
      filter(
        ([, multiItemQuotes]) =>
          !this.config?.multiBasketMaximum ||
          multiItemQuotes?.length < this.config.multiBasketMaximum
      ),
      map(([{ selectedItem }]) => {
        return QuotesActions.addToSelectedItems({ selectedItem });
      })
    )
  );

  private get config() {
    return this.buildConfigService.config;
  }

  handleError() {
    this.errorService.handleError();
  }

  constructor(
    @Inject(ENVIRONMENT) private environment: Environment,
    private actions$: Actions,
    private authFacade: AuthFacade,
    private buildConfigService: BuildConfigService,
    private errorService: ErrorService,
    private featureConfig: FeatureConfigFacade,
    private loaderService: LoaderService,
    private quotesService: QuotesApiService,
    private router: Router,
    private store: Store<QuotesPartialState & FeatureConfigPartialState>
  ) {}

  ngrxOnInitEffects(): Action {
    return QuotesActions.init();
  }

  private get quoteRemoteStateUnstable$(): Observable<boolean> {
    return this.store.pipe(
      select(getQuotesRemoteStateUnstable),
      filter((unstable: boolean) => !unstable),
      first()
    );
  }
}
