import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  Input,
  TemplateRef,
} from '@angular/core';

@Component({
  selector: 'ui-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ListComponent implements AfterViewInit {
  @Input() items = [];

  @ContentChild('listItem', { static: false }) listItemRef!: TemplateRef<void>;

  ngAfterViewInit() {
    !this.listItemRef &&
      console.warn(
        `The template must include a reference and a defined context:
        <ng-template #listItem let-item>
        <ui-list-item  [icon]="icon" >{{item}}</ui-list-item>
        </ng-template>`
      );
  }
}
