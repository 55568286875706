import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

/**
 * Note that these variant names may change
 */
export type BoxHighlight =
  | 'highlight-primary'
  | 'highlight-advertising'
  | 'highlight-feedback'
  | 'highlight-error'
  | 'default';
export type BoxHighlightPosition = 'left' | 'right' | 'top' | 'bottom';
export type BoxBorderRadius = 'none' | 'sm' | 'md' | 'lg';

@Component({
  selector: 'ui-box',
  templateUrl: './box.component.html',
  styleUrls: ['./box.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BoxComponent {
  @Input() selectable = false;
  @Input() selected = false;
  @Input() highlight: BoxHighlight = 'default';
  @Input() highlightPosition: BoxHighlightPosition = 'top';
  @Input() borderRadius: BoxBorderRadius = 'none';
}
