<ui-panel variant="highlight-feedback">
  <ui-panel-header>
    <ui-panel-title *ngIf="multiDetails?.length; else singleDetails"
      >Protection for your:</ui-panel-title
    >
    <ng-template #singleDetails>
      <ui-panel-title
        >Protection for your {{ brand }} {{ boilerType }}</ui-panel-title
      >
    </ng-template>
  </ui-panel-header>
  <ui-panel-body class="detail-panel" *ngIf="detail || multiDetails">
    <ng-container *ngIf="detail">
      <ui-panel-display-info-row
        *ngFor="let row of detail"
        [data]="row"
      ></ui-panel-display-info-row>
    </ng-container>
    <ui-panel-display-info-section
      *ngIf="multiDetails?.length"
      [data]="multiDetails"
      [footer]="multiDetailsFooter"
    ></ui-panel-display-info-section>
    <ng-container *ngIf="multiPaymentDetails?.length">
      <ui-panel-display-info-row
        *ngFor="let row of multiPaymentDetails"
        [data]="row"
      ></ui-panel-display-info-row>
    </ng-container>

    <ui-panel-content-row>
      <wl-cover-starting-date
        [panel]="false"
        [isHeating]="isHeating"
      ></wl-cover-starting-date>
    </ui-panel-content-row>

    <ui-panel-content-row id="terms-link">
      <div *ngIf="isInsurance; else elseBlock">
        <a class="link" target="_blank" [href]="terms?.link"
          >Terms and conditions</a
        ><br />
        <a class="link mt-2" target="_blank" [href]="productInfo?.link"
          >Insurance Product Information Document</a
        >
      </div>
      <ng-template #elseBlock>
        <a
          class="link"
          target="_blank"
          [href]="terms?.link"
          (click)="termsClick.emit()"
          >Terms and conditions</a
        >
      </ng-template>
    </ui-panel-content-row>
  </ui-panel-body>
</ui-panel>
