import { Component, Input } from '@angular/core';
import { BoxHighlight, BoxHighlightPosition } from '../../box';

@Component({
  selector: 'ui-form-card',
  templateUrl: './form-card.component.html',
})
export class FormCardComponent {
  @Input() highlight: BoxHighlight = 'default';
  @Input() highlightPosition: BoxHighlightPosition = 'top';
}
