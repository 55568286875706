import { FeatureConfigFacade } from '@common/data-access-feature-config';
import { ReinstateSidedoorConfig } from '@common/util-models';
import { map } from 'rxjs/operators';

export const featureConfigGoogleTagGlobalPropsFactory = (
  featureConfigFacade: FeatureConfigFacade
) => {
  return featureConfigFacade.sidedoor$<ReinstateSidedoorConfig>().pipe(
    map((sideDoorConfig) => ({
      clientName: sideDoorConfig.gaClientIdentifier,
    }))
  );
};
