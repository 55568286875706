import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'ui-list-item',
  templateUrl: './list-item.component.html',
  styleUrls: ['./list-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ListItemComponent {
  /**
   * Sets the icon glyth; [icon]="'tick'"
   */
  @Input() icon: string | undefined;

  /**
   * Sets the icon background fill colour; [iconFill]="--error"
   */
  @Input() iconFill!: string;
}
