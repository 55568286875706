<footer>
  <dgx-ui-wl-footer-navigation
    (clicked)="handleLinkClick($event)"
    [protectLinks]="protectLinks"
    [moreLinks]="moreLinks"
    [usefulLinks]="usefulLinks"
    [legalLinks]="legalLinks"
  ></dgx-ui-wl-footer-navigation>

  <dgx-ui-wl-footer-legal>
    <ng-content></ng-content>
  </dgx-ui-wl-footer-legal>

  <dgx-ui-wl-footer-social
    [copyrightOwner]="copyrightOwner"
    [socialLinks]="socialLinks"
  ></dgx-ui-wl-footer-social>
</footer>
