<div class="footer-legal">
  <div class="container">
    <div class="md-row xs--stack xs--middle sm--middle">
      <div class="xs-12">
        <p class="footer-legal__content" data-test-hook="legal-text">
          <ng-content></ng-content>
        </p>
        <p class="footer-legal__content">
          This site is protected by reCAPTCHA and the Google
          <a
            href="https://policies.google.com/privacy"
            class="footer-legal__link"
            target="_blank"
          >
            Privacy Policy
          </a>
          and
          <a
            href="https://policies.google.com/terms"
            class="footer-legal__link"
            target="_blank"
          >
            Terms of Service
          </a>
          apply.
        </p>
      </div>
    </div>
  </div>
</div>
