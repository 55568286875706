import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import {
  Appliance,
  ApplianceDetailsConfig,
  ApplianceFormFields,
  ApplianceFormSubmit,
  ApplianceFormValue,
  Brand,
  isRemoteDataLoading,
  Normalize,
  RemoteData,
} from '@common/util-models';
import { AnalyticsData } from '@domgen/dgx-fe-business-models';
import { InvalidFormSubmissionPayload } from '@domgen/dgx-fe-dynamic-form-builder';
import { Observable } from 'rxjs';
import { ApplianceDetailsFormConfigService } from './appliance-details-form-config.service';
import { ApplianceDetailsFormStateService } from './appliance-details-form-state.service';

const guaranteeQuestionnaireContent = `<h4>Your boiler is still under guarantee </h4><p>
Give us a call so we can offer you a maintenance and support plan that
suits you:
</p>
<p>
<a class="phone" href="tel:08005614493">0800 561 4493</a><br />
Choose Option 2 then Option 1
</p>
<p>
We're available: <br />
8am to 8pm Monday - Friday<br />
9am to 5pm Saturday - Sunday
</p>`;
@Component({
  selector: 'sales-appliance-details-form',
  templateUrl: './appliance-details-form.component.html',
  styleUrls: ['./appliance-details-form.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    ApplianceDetailsFormStateService,
    ApplianceDetailsFormConfigService,
  ],
})
export class ApplianceDetailsFormComponent {
  @Output() appliance: Observable<Appliance | undefined> = this
    .applianceDetailsFormService.appliance$;

  @Output() analytics: EventEmitter<AnalyticsData> = new EventEmitter();

  @Output()
  invalidSubmit: EventEmitter<InvalidFormSubmissionPayload> = new EventEmitter();

  @Output()
  validatedSubmit: Observable<ApplianceFormSubmit> = this
    .applianceDetailsFormService.validatedSubmit$;

  @Output()
  validatedForm: Observable<ApplianceFormSubmit> = this
    .applianceDetailsFormService.validatedForm$;

  @Output() formChanges: Observable<FormGroup> = this
    .applianceDetailsFormService.form$;

  @Output()
  valueChanges: Observable<ApplianceFormValue> = this
    .applianceDetailsFormService.formValue$;

  @Output()
  formValidationMessagesChanges: Observable<Normalize<Normalize<string>>> = this
    .applianceDetailsFormService.formValidationMessages$;

  @Input() validate = false;

  @Input()
  guaranteeQuestionnaireContent: SafeHtml = this.sanitizer.bypassSecurityTrustHtml(
    guaranteeQuestionnaireContent
  );
  @Input() set cmsFormData(
    setCmsFormData: ApplianceFormFields | null | undefined
  ) {
    if (setCmsFormData) {
      this.applianceDetailsFormService.setCmsFormData(setCmsFormData);
    }
  }

  @Input() set prefilledForm(
    prefilledForm: ApplianceFormSubmit | null | undefined
  ) {
    this.applianceDetailsFormService.setPrefilledFormData(prefilledForm);
  }

  @Input() set appliances(appliances: Appliance[] | null | undefined) {
    this.applianceDetailsFormService.setAppliances(appliances);
  }

  @Input() set brands(brands: Brand[] | null | undefined) {
    this.applianceDetailsFormService.setBrands(brands);
  }

  @Input() set brandsLoaded(brandsLoaded: RemoteData | null | undefined) {
    this.applianceDetailsFormService.setBrandsLoaded(
      brandsLoaded ? isRemoteDataLoading(brandsLoaded) : true
    );
  }

  @Input() set fieldsOptions(config: ApplianceDetailsConfig | null) {
    this.applianceDetailsFormService.setConfig(config);
  }

  readonly vm$ = this.applianceDetailsFormService.vm$;

  constructor(
    private readonly applianceDetailsFormService: ApplianceDetailsFormStateService,
    private sanitizer: DomSanitizer
  ) {}

  handleValidFormSubmission(value: ApplianceFormValue) {
    this.applianceDetailsFormService.updateSubmittedForm(value);
  }

  handleInvalidFormSubmission(value: InvalidFormSubmissionPayload) {
    this.invalidSubmit.emit(value);
  }

  handleAnalytics(event: AnalyticsData) {
    this.analytics.emit(event);
  }
}
