import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ValidationIconComponent } from './validation-icon/validation-icon.component';
import { FormCardComponent } from './form-card/form-card.component';
import { BoxComponentsModule } from '../box';

const components = [ValidationIconComponent, FormCardComponent];
@NgModule({
  imports: [CommonModule, BoxComponentsModule],
  declarations: [...components],
  exports: [...components],
})
export class FormComponentsModule {}
