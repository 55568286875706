<div class="footer-navigation">
  <div class="container md-row sm-col">
    <div
      *ngIf="protectLinks?.length"
      class="footer-navigation__group xs-12 md-6 lg-3"
      data-test-hook="protect"
    >
      <h4 class="footer-navigation__title">Products we protect</h4>
      <ul class="footer-navigation__list">
        <li *ngFor="let link of protectLinks">
          <ng-container
            [ngTemplateOutlet]="footerNavigationLink"
            [ngTemplateOutletContext]="{
              navigationLink: link
            }"
          ></ng-container>
        </li>
      </ul>
    </div>

    <div
      *ngIf="moreLinks?.length"
      class="footer-navigation__group xs-12 md-6 lg-3"
      data-test-hook="more"
    >
      <h4 class="footer-navigation__title">More from us</h4>
      <ul class="footer-navigation__list">
        <li *ngFor="let link of moreLinks">
          <ng-container
            [ngTemplateOutlet]="footerNavigationLink"
            [ngTemplateOutletContext]="{
              navigationLink: link
            }"
          ></ng-container>
        </li>
      </ul>
    </div>

    <div
      *ngIf="usefulLinks?.length"
      class="footer-navigation__group xs-12 md-6 lg-3"
      data-test-hook="useful"
    >
      <h4 class="footer-navigation__title">Useful links</h4>
      <ul class="footer-navigation__list">
        <li *ngFor="let link of usefulLinks">
          <ng-container
            [ngTemplateOutlet]="footerNavigationLink"
            [ngTemplateOutletContext]="{
              navigationLink: link
            }"
          ></ng-container>
        </li>
      </ul>
    </div>

    <div
      *ngIf="legalLinks?.length"
      class="footer-navigation__group xs-12 md-6 lg-3"
      data-test-hook="legal"
    >
      <h4 class="footer-navigation__title">Legal</h4>
      <ul class="footer-navigation__list">
        <li *ngFor="let link of legalLinks">
          <ng-container
            [ngTemplateOutlet]="footerNavigationLink"
            [ngTemplateOutletContext]="{
              navigationLink: link
            }"
          ></ng-container>
        </li>
      </ul>
    </div>
  </div>
</div>

<ng-template #footerNavigationLink let-navigationLink="navigationLink">
  <a
    *ngIf="navigationLink.routerPath; else externalFooterNavigationLink"
    (click)="handleLinkClick($event, navigationLink.label)"
    [routerLink]="[navigationLink.routerPath]"
    class="footer-navigation__link"
  >
    {{ navigationLink.label }}
  </a>

  <ng-template #externalFooterNavigationLink>
    <a
      (click)="handleLinkClick($event, navigationLink.label)"
      [attr.rel]="navigationLink.newWindow ? 'noopener noreferrer' : undefined"
      [attr.target]="navigationLink.newWindow ? '_blank' : undefined"
      [href]="navigationLink.href"
      class="footer-navigation__link"
    >
      {{ navigationLink.label }}
    </a>
  </ng-template>
</ng-template>
