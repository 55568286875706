<div class="footer-social">
  <div class="container">
    <div class="md-row xs--stack xs--middle sm--middle">
      <div class="xs-12 md-6">
        <p *ngIf="copyrightOwner" class="footer-social__copyright">
          &copy; {{ currentYear }} {{ copyrightOwner }}
        </p>
      </div>
      <div class="xs-12 md-6">
        <ul *ngIf="socialLinks" class="footer-social__list">
          <li *ngFor="let link of socialLinks">
            <a
              [href]="link.href"
              class="icon icon--xl icon-{{ link.icon }} footer-social__link"
              [attr.aria-label]="link.label"
              target="_blank"
              rel="noopener noreferrer"
            ></a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
