<header class="header">
  <div class="container">
    <div class="xs-row">
      <div class="header__banner xs-12 md-6 flex justify--end align--center">
        <div class="header__tag-line">Powered by</div>
        <img
          class="dg-logo"
          src="assets/images/dg-logo-colour.png"
          role="img"
          height="30"
          alt="Domestic & General"
        />
      </div>

      <div class="header__logo-wrapper xs-12 md-6 flex align--center">
        <a href="/">
          <img
            src="assets/images/sky-protect.svg"
            class="sky-logo"
            role="img"
            alt="Sky Protect"
            height="30"
          />
        </a>
      </div>
    </div>
  </div>
</header>
