import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { NavigationLink } from '@domgen/dgx-fe-business-models';

@Component({
  selector: 'dgx-ui-wl-footer',
  templateUrl: './footer.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FooterComponent {
  @Input() copyrightOwner?: string;
  @Input() legalLinks?: NavigationLink[];
  @Input() moreLinks?: NavigationLink[];
  @Input() protectLinks?: NavigationLink[];
  @Input() socialLinks?: NavigationLink[];
  @Input() usefulLinks?: NavigationLink[];

  @Output() clicked = new EventEmitter<{
    event: MouseEvent;
    link: string;
  }>();

  public handleLinkClick(event: { event: MouseEvent; link: string }) {
    this.clicked.emit(event);
  }
}
