import { BoxComponent } from './box.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

const components = [BoxComponent];
@NgModule({
  imports: [CommonModule],
  declarations: [...components],
  exports: [...components],
})
export class BoxComponentsModule {}
